/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-touchevents-addtest-prefixed-prefixedcss !*/
!(function (e, n, t) {
  function r(e, n) {
    return typeof e === n;
  }
  function o() {
    var e, n, t, o, i, s, a;
    for (var l in _)
      if (_.hasOwnProperty(l)) {
        if (
          ((e = []),
          (n = _[l]),
          n.name &&
            (e.push(n.name.toLowerCase()),
            n.options && n.options.aliases && n.options.aliases.length))
        )
          for (t = 0; t < n.options.aliases.length; t++)
            e.push(n.options.aliases[t].toLowerCase());
        for (o = r(n.fn, 'function') ? n.fn() : n.fn, i = 0; i < e.length; i++)
          (s = e[i]),
            (a = s.split('.')),
            1 === a.length
              ? (Modernizr[a[0]] = o)
              : (!Modernizr[a[0]] ||
                  Modernizr[a[0]] instanceof Boolean ||
                  (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])),
                (Modernizr[a[0]][a[1]] = o)),
            S.push((o ? '' : 'no-') + a.join('-'));
      }
  }
  function i(e) {
    var n = x.className,
      t = Modernizr._config.classPrefix || '';
    if ((b && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      var r = new RegExp('(^|\\s)' + t + 'no-js(\\s|$)');
      n = n.replace(r, '$1' + t + 'js$2');
    }
    Modernizr._config.enableClasses &&
      ((n += ' ' + t + e.join(' ' + t)),
      b ? (x.className.baseVal = n) : (x.className = n));
  }
  function s(e, n) {
    if ('object' == typeof e) for (var t in e) w(e, t) && s(t, e[t]);
    else {
      e = e.toLowerCase();
      var r = e.split('.'),
        o = Modernizr[r[0]];
      if ((2 == r.length && (o = o[r[1]]), 'undefined' != typeof o))
        return Modernizr;
      (n = 'function' == typeof n ? n() : n),
        1 == r.length
          ? (Modernizr[r[0]] = n)
          : (!Modernizr[r[0]] ||
              Modernizr[r[0]] instanceof Boolean ||
              (Modernizr[r[0]] = new Boolean(Modernizr[r[0]])),
            (Modernizr[r[0]][r[1]] = n)),
        i([(n && 0 != n ? '' : 'no-') + r.join('-')]),
        Modernizr._trigger(e, n);
    }
    return Modernizr;
  }
  function a(e, n) {
    return !!~('' + e).indexOf(n);
  }
  function l() {
    return 'function' != typeof n.createElement
      ? n.createElement(arguments[0])
      : b
      ? n.createElementNS.call(n, 'http://www.w3.org/2000/svg', arguments[0])
      : n.createElement.apply(n, arguments);
  }
  function u() {
    var e = n.body;
    return e || ((e = l(b ? 'svg' : 'body')), (e.fake = !0)), e;
  }
  function f(e, t, r, o) {
    var i,
      s,
      a,
      f,
      c = 'modernizr',
      p = l('div'),
      d = u();
    if (parseInt(r, 10))
      for (; r--; )
        (a = l('div')), (a.id = o ? o[r] : c + (r + 1)), p.appendChild(a);
    return (
      (i = l('style')),
      (i.type = 'text/css'),
      (i.id = 's' + c),
      (d.fake ? d : p).appendChild(i),
      d.appendChild(p),
      i.styleSheet
        ? (i.styleSheet.cssText = e)
        : i.appendChild(n.createTextNode(e)),
      (p.id = c),
      d.fake &&
        ((d.style.background = ''),
        (d.style.overflow = 'hidden'),
        (f = x.style.overflow),
        (x.style.overflow = 'hidden'),
        x.appendChild(d)),
      (s = t(p, e)),
      d.fake
        ? (d.parentNode.removeChild(d), (x.style.overflow = f), x.offsetHeight)
        : p.parentNode.removeChild(p),
      !!s
    );
  }
  function c(e) {
    return e
      .replace(/([A-Z])/g, function (e, n) {
        return '-' + n.toLowerCase();
      })
      .replace(/^ms-/, '-ms-');
  }
  function p(n, t, r) {
    var o;
    if ('getComputedStyle' in e) {
      o = getComputedStyle.call(e, n, t);
      var i = e.console;
      if (null !== o) r && (o = o.getPropertyValue(r));
      else if (i) {
        var s = i.error ? 'error' : 'log';
        i[s].call(
          i,
          'getComputedStyle returning null, its possible modernizr test results are inaccurate'
        );
      }
    } else o = !t && n.currentStyle && n.currentStyle[r];
    return o;
  }
  function d(n, r) {
    var o = n.length;
    if ('CSS' in e && 'supports' in e.CSS) {
      for (; o--; ) if (e.CSS.supports(c(n[o]), r)) return !0;
      return !1;
    }
    if ('CSSSupportsRule' in e) {
      for (var i = []; o--; ) i.push('(' + c(n[o]) + ':' + r + ')');
      return (
        (i = i.join(' or ')),
        f(
          '@supports (' + i + ') { #modernizr { position: absolute; } }',
          function (e) {
            return 'absolute' == p(e, null, 'position');
          }
        )
      );
    }
    return t;
  }
  function h(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function (e, n, t) {
        return n + t.toUpperCase();
      })
      .replace(/^-/, '');
  }
  function m(e, n, o, i) {
    function s() {
      f && (delete j.style, delete j.modElem);
    }
    if (((i = r(i, 'undefined') ? !1 : i), !r(o, 'undefined'))) {
      var u = d(e, o);
      if (!r(u, 'undefined')) return u;
    }
    for (
      var f, c, p, m, v, g = ['modernizr', 'tspan', 'samp'];
      !j.style && g.length;

    )
      (f = !0), (j.modElem = l(g.shift())), (j.style = j.modElem.style);
    for (p = e.length, c = 0; p > c; c++)
      if (
        ((m = e[c]),
        (v = j.style[m]),
        a(m, '-') && (m = h(m)),
        j.style[m] !== t)
      ) {
        if (i || r(o, 'undefined')) return s(), 'pfx' == n ? m : !0;
        try {
          j.style[m] = o;
        } catch (y) {}
        if (j.style[m] != v) return s(), 'pfx' == n ? m : !0;
      }
    return s(), !1;
  }
  function v(e, n) {
    return function () {
      return e.apply(n, arguments);
    };
  }
  function g(e, n, t) {
    var o;
    for (var i in e)
      if (e[i] in n)
        return t === !1
          ? e[i]
          : ((o = n[e[i]]), r(o, 'function') ? v(o, t || n) : o);
    return !1;
  }
  function y(e, n, t, o, i) {
    var s = e.charAt(0).toUpperCase() + e.slice(1),
      a = (e + ' ' + z.join(s + ' ') + s).split(' ');
    return r(n, 'string') || r(n, 'undefined')
      ? m(a, n, o, i)
      : ((a = (e + ' ' + E.join(s + ' ') + s).split(' ')), g(a, n, t));
  }
  var _ = [],
    C = {
      _version: '3.6.0',
      _config: {
        classPrefix: '',
        enableClasses: !1,
        enableJSClass: !0,
        usePrefixes: !0,
      },
      _q: [],
      on: function (e, n) {
        var t = this;
        setTimeout(function () {
          n(t[e]);
        }, 0);
      },
      addTest: function (e, n, t) {
        _.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function (e) {
        _.push({ name: null, fn: e });
      },
    },
    Modernizr = function () {};
  (Modernizr.prototype = C), (Modernizr = new Modernizr());
  var w,
    S = [];
  !(function () {
    var e = {}.hasOwnProperty;
    w =
      r(e, 'undefined') || r(e.call, 'undefined')
        ? function (e, n) {
            return n in e && r(e.constructor.prototype[n], 'undefined');
          }
        : function (n, t) {
            return e.call(n, t);
          };
  })();
  var x = n.documentElement,
    b = 'svg' === x.nodeName.toLowerCase();
  (C._l = {}),
    (C.on = function (e, n) {
      this._l[e] || (this._l[e] = []),
        this._l[e].push(n),
        Modernizr.hasOwnProperty(e) &&
          setTimeout(function () {
            Modernizr._trigger(e, Modernizr[e]);
          }, 0);
    }),
    (C._trigger = function (e, n) {
      if (this._l[e]) {
        var t = this._l[e];
        setTimeout(function () {
          var e, r;
          for (e = 0; e < t.length; e++) (r = t[e])(n);
        }, 0),
          delete this._l[e];
      }
    }),
    Modernizr._q.push(function () {
      C.addTest = s;
    });
  var T = 'Moz O ms Webkit',
    z = C._config.usePrefixes ? T.split(' ') : [];
  C._cssomPrefixes = z;
  var P = { elem: l('modernizr') };
  Modernizr._q.push(function () {
    delete P.elem;
  });
  var j = { style: P.elem.style };
  Modernizr._q.unshift(function () {
    delete j.style;
  });
  var E = C._config.usePrefixes ? T.toLowerCase().split(' ') : [];
  (C._domPrefixes = E), (C.testAllProps = y);
  var N = function (n) {
    var r,
      o = O.length,
      i = e.CSSRule;
    if ('undefined' == typeof i) return t;
    if (!n) return !1;
    if (
      ((n = n.replace(/^@/, '')),
      (r = n.replace(/-/g, '_').toUpperCase() + '_RULE'),
      r in i)
    )
      return '@' + n;
    for (var s = 0; o > s; s++) {
      var a = O[s],
        l = a.toUpperCase() + '_' + r;
      if (l in i) return '@-' + a.toLowerCase() + '-' + n;
    }
    return !1;
  };
  C.atRule = N;
  var L = (C.prefixed = function (e, n, t) {
      return 0 === e.indexOf('@')
        ? N(e)
        : (-1 != e.indexOf('-') && (e = h(e)), n ? y(e, n, t) : y(e, 'pfx'));
    }),
    O =
      ((C.prefixedCSS = function (e) {
        var n = L(e);
        return n && c(n);
      }),
      C._config.usePrefixes
        ? ' -webkit- -moz- -o- -ms- '.split(' ')
        : ['', '']);
  C._prefixes = O;
  var k = (C.testStyles = f);
  Modernizr.addTest('touchevents', function () {
    var t;
    if ('ontouchstart' in e || (e.DocumentTouch && n instanceof DocumentTouch))
      t = !0;
    else {
      var r = [
        '@media (',
        O.join('touch-enabled),('),
        'heartz',
        ')',
        '{#modernizr{top:9px;position:absolute}}',
      ].join('');
      k(r, function (e) {
        t = 9 === e.offsetTop;
      });
    }
    return t;
  }),
    o(),
    delete C.addTest,
    delete C.addAsyncTest;
  for (var q = 0; q < Modernizr._q.length; q++) Modernizr._q[q]();
  e.Modernizr = Modernizr;
})(window, document);
